import { getExhibitKeys } from "@treasured/client";
import {
  Box,
  Card,
  DarkMode,
  Grid,
  Heading,
  HStack,
  Icon,
  Input,
  Text,
} from "@treasured/ui";
import fs from "fs/promises";
import Fuse from "fuse.js";
import { GetStaticProps, InferGetStaticPropsType } from "next";
import Link from "next/link";
import path from "path";
import { useMemo, useState } from "react";
import { MdOutlineCloud, MdOutlineHome } from "react-icons/md";

export const getStaticProps: GetStaticProps<{
  localSlugs: string[];
  slugs: string[];
}> = async () => {
  // Try AWS
  let slugs = [];
  try {
    slugs = await getExhibitKeys();
  } catch (e) {}

  // Try public/ folder
  let localSlugs = [];
  if (process.env.NODE_ENV === "development") {
    const buildsDir = path.join(process.cwd(), "public/builds/");
    try {
      const dirEntries = await fs.readdir(buildsDir, { withFileTypes: true });
      for (const dirEntry of dirEntries) {
        if (dirEntry.isDirectory()) {
          const slug = dirEntry.name;
          if (!localSlugs.includes(slug)) {
            localSlugs.push(slug);
          }
        }
      }
    } catch (e) {}
  }

  return {
    props: {
      localSlugs,
      slugs,
    },
    revalidate: 10,
  };
};

export default function Home({
  localSlugs,
  slugs,
}: InferGetStaticPropsType<typeof getStaticProps>) {
  const [query, setQuery] = useState("");

  const allSlugs = useMemo(
    () => [
      ...localSlugs.map((slug) => ({
        slug,
        location: "local",
      })),
      ...slugs.map((slug) => ({
        slug,
        location: "remote",
      })),
    ],
    [localSlugs, slugs]
  );

  const fuse = useMemo(
    () =>
      new Fuse(allSlugs, {
        keys: ["slug"],
        threshold: 0.3,
        isCaseSensitive: false,
        shouldSort: true,
        sortFn: (a, b) => {
          if (allSlugs[a.idx].location === allSlugs[b.idx].location) {
            return a.score - b.score;
          } else if (allSlugs[a.idx].location === "local") {
            return -1;
          } else {
            return 1;
          }
        },
      }),
    [allSlugs]
  );

  const results = useMemo(
    () => (query ? fuse.search(query).map((result) => result.item) : allSlugs),
    [query, fuse, allSlugs]
  );

  return (
    <DarkMode>
      <Box
        px={{ base: 4, sm: 8, md: 16, lg: 32 }}
        py={{ base: 16, sm: 16, md: 16, lg: 32 }}
        overflowY="auto"
        h="full"
        bgGradient="linear(to-br, pink.700, purple.900, gray.900, gray.900)"
      >
        <Box mb={16} maxWidth="xl" color="white">
          <Heading as="h1" size="3xl" mb={4}>
            3D Scenes
          </Heading>
          <Heading as="h2" size="lg" fontWeight="light" mb={8}>
            Showcase of real estate, cultural heritage, and other 3D
            environments in our Web Player
          </Heading>

          <Input
            type="search"
            placeholder="Search"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            autoFocus
            color="gray.800"
            variant="solid"
            size="lg"
          />
        </Box>

        {results.length === 0 && (
          <Box maxWidth="xl" color="white">
            <Text fontSize="xl" fontWeight="light">
              No results found
            </Text>
          </Box>
        )}

        {results.length > 0 && (
          <Grid templateColumns="repeat(auto-fill, minmax(300px, 1fr))" gap={6}>
            {results.map(({ location, slug }) => (
              <Link
                href={`/player/${slug}${
                  location === "local" ? "?local=true" : ""
                }`}
                key={`${slug}-${location}`}
                passHref
              >
                <Card
                  as="a"
                  variant="acrylic"
                  p={6}
                  transition="all 0.2s ease-in-out"
                  _hover={{
                    boxShadow: "lg",
                    transform: "scale(1.025)",
                    bg: "blackAlpha.500",
                  }}
                  role="group"
                >
                  <HStack
                    justifyContent="space-between"
                    w="full"
                    overflow="hidden"
                    spacing={4}
                  >
                    <Box flex="1" minW={0}>
                      <Heading
                        as="h3"
                        size="sm"
                        fontWeight="bold"
                        textOverflow="ellipsis"
                        overflow="hidden"
                        whiteSpace="nowrap"
                        mb={1}
                      >
                        {slug}
                      </Heading>
                      <Text
                        fontSize="xs"
                        fontWeight="light"
                        color="whiteAlpha.700"
                        textTransform="uppercase"
                        letterSpacing="widest"
                      >
                        {location}
                      </Text>
                    </Box>
                    <Icon
                      as={location === "local" ? MdOutlineHome : MdOutlineCloud}
                      w={6}
                      h={6}
                      color="whiteAlpha.500"
                      transition="all 0.2s ease-in-out"
                      _groupHover={{
                        color: "whiteAlpha.800",
                      }}
                    />
                  </HStack>
                </Card>
              </Link>
            ))}
          </Grid>
        )}
      </Box>
    </DarkMode>
  );
}
